

















































































































































































































































































































































































































.dialogTitle {
  display: flex;
}

.tancengTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.backBtn {
  margin-top: 3px;
}
