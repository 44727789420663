








































































.invoiceTypeTitle{
  display: flex;
  .invoiceTypeName{
    width: 96px;
    height: 36px;
    color: #ffffff;
    font-size: 12px;
    padding-left: 16px;
    margin-right: 10px;
    line-height: 34px;
    background: url("~@/assets/img/form/invoiceName.png");
  }
  .invoiceCompanyName{
    color:#000;
    font-size: 16px;
    margin-top: 1px;
    line-height: 36px;
  }
}
.invoiceLists{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
.invoiceList{
  display: flex;
  flex:1;
.invoiceCount{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex:1;
  position: relative;
  margin: 10px 0;
&::before{
   content:"";
   position: absolute;
   right:0;
   top:50%;
   margin-top: -15px;
   height: 30px;
   width: 1px;
   background:#ccc;
 }
&:last-child::before{
   display: none;
 }
span{
  color:#000;
  font-size: 16px;
  line-height: 26px;
}
span+span{
  color:#999;
  font-size: 12px;
  line-height: 22px;
}
}
}
.invoiceListBtn{
  margin-left: 10px;
.el-button{
  width: 53px;
}
}
}
