















































































.invoicesBox{
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  .invoice{
    width:150px;
    height:190px;
    padding:30px 20px 20px;
    box-sizing: border-box;
    border:1px solid #ccc;
    border-radius:4px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    &.active{
      transform: scale(1.05);
      box-shadow:0px 1px 7px 0px rgba(0, 0, 0, 0.1);
      background: rgba(255,255,255,.5);
    }
    &:hover{
      transform: scale(1.05);
      background: rgba(255,255,255,.2);
    }
    .invoiceIcon{
      width: 68px;
      height: 68px;
    }
    .icon{
      font-size: 68px;
    }
    span{
      color:#000;
      font-size: 16px;
    }
    p{
      font-size: 12px;
      color: #000;
      text-align: center;
    }
  }
}
.invoicesTitle {
  display: flex;
  line-height: 24px;
  margin-top: 20px;
  font-size: 14px;
  color: #F2A500;
  .invoiceTitleIcon {
    width: 16px;
    height: 16px;
    margin-right: 10px;
    .icon {
      font-size: 16px;
      color: #F19E00;
    }
  }
}
.invoicesContent {
  p {
    font-size: 12px;
    line-height: 20px;
    text-indent: -20px;
    padding-left: 24px;
  }
}
.tancengBtn{
  margin-top: 30px;
  text-align: center;
  .el-button--small{
    width: 80px;
    height: 30px;
  }
}
